import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
// CUSTOM COMPONENTS
import { Paragraph, H6 } from 'components/typography';
import AvatarLoading from 'components/avatar-loading';
import FlexRowAlign from 'components/flexbox/FlexRowAlign';
import { getData } from 'api-handlers/apiFunctions';
import { useContext, useEffect } from 'react';
import { UserContext } from 'contexts/userContext';
import Skeleton from '@mui/material/Skeleton';



export default function UserAccount() {
  const { userProfilePic, updateUserProfilePic } = useContext(UserContext);
  const { userProfileInfo, updateUserProfileInfo } = useContext(UserContext);

  const fetchUserProfilePic = async () => {
    try {
      if (!localStorage.getItem('userProfilePic')) {
        const result = await getData(process.env.REACT_APP_USER_FETCH_PROFILE_IMAGE_ENDPOINT);
        if (result.success && result.data) {
          if (result.data?.profile_image_link) {
            localStorage.setItem("userProfilePic", JSON.stringify(result.data?.profile_image_link))
            updateUserProfilePic(result.data?.profile_image_link)
          }

        }
      }
    }
    catch (error) { console.log({ error }) }
    finally {
    }

  };


  const fetchUserInfo = async () => {
    try {
      if (!userProfileInfo) {
        const result = await getData(process.env.REACT_APP_USER_INFO_ENDPOINT)
        if (result.success) {
          updateUserProfileInfo(result.data.user);
        }

      }
    }
    catch (error) { console.log({ error }) }
    finally {
    }
  };



  useEffect(() => {
    fetchUserProfilePic();
    fetchUserInfo()
    console.log("... User Info Fetched ...")
  }, [])




  return (
    <FlexRowAlign flexDirection="column" py={2}>
      <Badge color="primary">
      <AvatarLoading
            alt="user"
            percentage={60}
            src={userProfilePic}
            sx={{
              width: 50,
              height: 50
            }}
          />


      </Badge>

      <Box textAlign="center" pt={1} pb={2}>
        <H6 variant="outlined" label="Welcome Back" size="small" />

        <Paragraph fontSize={16} fontWeight={600} mt={1}>
          {userProfileInfo?.username}
        </Paragraph>

        <Paragraph fontSize={13} fontWeight={500} color="text.secondary" mt={0.25}>
          {userProfileInfo?.role}
        </Paragraph>
      </Box>
    </FlexRowAlign>
  );

}