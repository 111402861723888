import api from './api';


export const setSession = (keyname , data) => {
    localStorage.setItem( keyname , data);
}

export const getSession = (keyname) => {
    return localStorage.getItem( keyname );
}

export const deleteSession = (keyname) => {
  localStorage.removeItem( keyname );
  sessionStorage.removeItem( keyname );
}


export const getData = async (endpoint , customHeaders = {} ) => {
  try {
    const response = await api.get(endpoint , { headers: { ...customHeaders } } );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const postData = async (endpoint, data , customHeaders = {}) => {
  try {
    console.log({endpoint , data})
    const response = await api.post(endpoint, data , { headers: { ...customHeaders } } );

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};


// export const putData = async (endpoint, data) => {
//   try {
//     const response = await api.put(endpoint, data);
//     return response.data;
//   } catch (error) {
//     console.error('Error posting data:', error);
//     throw error;
//   }
// };



export const putData = async (endpoint, data, customHeaders = {} ,  isFormData = false) => {
  try {
    const config = isFormData ? { customHeaders: { ...customHeaders ,  'Content-Type': 'multipart/form-data' } } : {};
      
    const response = await api.put(endpoint, data, { headers: { ...customHeaders } });
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};



export const deleteData = async (endpoint, data) => {
  try {
    const response = await api.delete(endpoint, { data });
    return response.data;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};
