// CUSTOM ICON COMPONENT
import duotone from 'icons/duotone';

// ==============================================================

// ==============================================================

export const navigations = [
{
  type: 'label',
  label: 'Dashboard'
}, 
{
  name: 'Home',
  path: '/dashboard',
  icon: duotone.RectangleCirclePlus
}, 


{
  name: 'APIs',
  icon: duotone.Apps,
  children: [    
    {
      name : "GET Jobs Listing",
      path: '/dashboard/api/docs/listing'  
    },
  ]
},

{
  name: 'Profile',
  icon: duotone.Accounts,
  path: '/dashboard/account'
}


// {
//   name: 'Users & Roles',
//   icon: duotone.UserList,
//   children: [
//   {
//     name: 'Users',
//     path: '/dashboard/users'
//   },  
//   {
//     name: 'Add User',
//     path: '/dashboard/add-user'
//   }, 
//   {
//     name : 'Roles',
//     path : '/dashboard/roles'
//   },
//   {
//     name: 'Add Role',
//     path: '/dashboard/add-role'
//   }, 


//   ]
// }, 
// {
//   name: 'Scrapers & Jobs',
//   icon: duotone.Invoice,
//   children: [
//     {
//     name: 'Scrapers',
//     path: '/dashboard/scrapers'
//     }
//   ]
// }, 


];