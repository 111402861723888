import { lazy } from 'react';
import Loadable from './Loadable';
import { GuestGuard } from 'components/auth';

// AUTHENTICATION RELATED PAGES
const LoginPage = Loadable(lazy(() => import('pages/auth/login')));
const RegisterPage = Loadable(lazy(() => import('pages/auth/register')));
const VerifyEmailPage = Loadable(lazy(() => import('pages/auth/verifyEmail')));
const ForgotPasswordPage = Loadable(lazy(() => import('pages/auth/forgetpassword')));
const UpdateForgetPasswordPage = Loadable(lazy(() => import('pages/auth/update-forgetpassword')));

const CheckoutPage = Loadable(lazy(() => import('pages/auth/onBoarding/checkout')));
const SelectSubsctiptionPage = Loadable(lazy(() => import('pages/auth/onBoarding/selectSubscriptionView')));
const SubscriptionDetailsPage = Loadable(lazy(() => import('pages/auth/onBoarding/selectPackageDetailsView')));


const UserInfoDetailsView = Loadable(lazy(() => import( 'pages/auth/onBoarding/userDetailsView')));


export const AuthRoutes = [
{
    element: <GuestGuard />,
    children: [
      {
        path: 'login',
        element: <LoginPage />
      },
      {
        path: 'register',
        element: <RegisterPage />
      },
      {
        path: 'verify-email/:token?',
        element: <VerifyEmailPage />
      },
      {
        path: 'forget-password',
        element: <ForgotPasswordPage />
      },
      {
        path: 'update-password/:token?',
        element: <UpdateForgetPasswordPage />
      },
      {
        path: '/select-subscription',
        element: <SelectSubsctiptionPage />
      },
      {
        path: '/subscription/:plan',
        element: <SubscriptionDetailsPage />
      },
      {
        path: '/checkout',
        element: <CheckoutPage />
      },
      {
        path: '/user-details',
        element: <UserInfoDetailsView />
      }

  ]
},

];