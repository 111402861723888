import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userProfileInfo, setUserProfileInfo] = useState(() => {
    return JSON.parse( localStorage.getItem('userInfo') ) || null });

  const [userProfilePic, setUserProfilePic] = useState(() => {
    return localStorage.getItem('userProfilePic') || '/static/user/user_logo_avatar.png';
  });
  



  const updateUserProfilePic = (newProfilePic) => {
    setUserProfilePic(newProfilePic);
    localStorage.setItem('userProfilePic', newProfilePic);
  };

  const updateUserProfileInfo = (userInfo) => {
    setUserProfileInfo(userInfo);
    localStorage.setItem('userInfo', JSON.stringify(userInfo) );
  };


  return (
    <UserContext.Provider value={{ userProfilePic,userProfileInfo, updateUserProfilePic , updateUserProfileInfo }}>
      {children}
    </UserContext.Provider>
  );
};
