import { lazy } from 'react';
import Loadable from './Loadable';
import { AuthGuard  } from 'components/auth';
import useSettings from 'hooks/useSettings';
import LayoutV1 from 'layouts/layout-1';
import LayoutV2 from 'layouts/layout-2';


// Dashboard HomePage
const DashboardPage = Loadable(lazy(() => import('pages/dashboard/dashboard-home')));
// API & Subscription
const APIInfoPage = Loadable(lazy(() => import('pages/dashboard/api-subscriptions')));
// Profile & Accounts
const Account = Loadable(lazy(() => import('pages/dashboard/accounts')));


const ActiveLayout = () => {
  const {
    settings
  } = useSettings();
  switch (settings.activeLayout) {
    case 'layout1':
      return <LayoutV1 />;
    case 'layout2':
      return <LayoutV2 />;
    default:
      return <LayoutV1 />;
  }
};
export const DashboardRoutes = [{
  path: 'dashboard',
  element: <AuthGuard>
        <ActiveLayout />
      </AuthGuard>,
  children: [
    {
      path: '',
      element: <DashboardPage />
    }, 
    {
      path: 'api/docs/listing',
      element: <APIInfoPage />
    }, 
    {
      path: 'account',
      element: <Account />
    },    
    // {
    //   path : "update-user",
    //   element : <UpdateUser />
    // },
    // {
    //   path: 'users',
    //   element: <UserListView />
    // }, 
    // {
    //   path: 'users/view/grid',
    //   element: <UserGridView />
    // }, 
    // {
    //   path : "roles",
    //   element : <RolesListView />
    // },
    // {
    //   path : "add-role",
    //   element : <RolesAddView />
    // },
    // {
    //   path : "update-role",
    //   element : <RolesUpdateView />
    // },

    // {
    //   path : "scrapers",
    //   element : <ScrapersListView />
    // },
    // {
    //   path : "scraper/:scraperName" ,
    //   element : <JobsListView />
    // }
  ]
}];

