import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// CUSTOM DEFINED HOOK
import useAuth from 'hooks/useAuth';
import useNavigate from 'hooks/useNavigate';
import useLocation from 'hooks/useLocation';
// LAYOUT BASED HOOK
import useLayout from 'layouts/layout-1/context/useLayout';
// CUSTOM COMPONENTS
import SidebarAccordion from './SidebarAccordion';
import { navigations } from 'layouts/layout-parts/navigation';
// CUSTOM STYLED COMPONENTS
import { ItemText, ListLabel, BulletIcon, ICON_STYLE, ExternalLink, NavItemButton } from 'layouts/layout-1/styles';
import { Box, Typography, Chip } from "@mui/material";
// ===========================================================================

// ===========================================================================

export default function MultiLevelMenu({
  sidebarCompact
}) {
  const API_METHODS = {"GET" : "success" , "POST" : "primary" , "PUT" : "primary" , "DELETE" : "primary" , "PATCH" : "primary"}

  function findMethodInString(inputString, methods) {
    for (const method of methods) {
      const regex = new RegExp(`\\b${method}\\b`, "i"); // Case insensitive, word boundary
      if (regex.test(inputString)) {
        return method; // Return the first matched method
      }
    }
    return null; // Return null if no method is found
  }  

  function splitMethodAndText(inputString, methods) {
    const words = inputString.split(" "); // Split the input string into words
    let method = null;
    let remainingText = [];
  
    // Check each word for an HTTP method from the API_METHODS
    for (const word of words) {
      if (methods[word.toUpperCase()]) {
        method = word.toUpperCase(); // Found the method
      } else if (method) {
        remainingText.push(word); // Add the rest of the words to the remainingText
      }
    }
  
    return {
      method: method || null, // Return the method found or null if none
      remainingText: remainingText.join(" ") // Join the remaining text back into a string
    };
  }
  


  function displayItem(name){
    // Call the splitMethodAndText function outside the JSX to get the method and remaining text
    const { method, remainingText } = splitMethodAndText(name, API_METHODS);

    // If a method is found in the string, show the Chip
    if (method) {
      return (
        <>
          <Chip 
            label={method} 
            color={API_METHODS[method]}
            size="small" 
          />
          {' '}
          {remainingText && remainingText.length > 0 && `${remainingText}`}
        </>
      );
    } else {
      // If no method is found, just display the item name
      return t(name);
    }    
  }

  const {
    user
  } = useAuth();
  const {
    t
  } = useTranslation();
  const navigate = useNavigate();
  const {
    pathname
  } = useLocation();
  const {
    handleCloseMobileSidebar
  } = useLayout();

  // HANDLE ACTIVE CURRENT PAGE
  const activeRoute = path => pathname === path ? 1 : 0;

  // HANDLE NAVIGATE TO ANOTHER PAGE
  const handleNavigation = path => {
    navigate(path);
    handleCloseMobileSidebar?.();
  };

  // ACTIVATE SIDEBAR COMPACT
  const COMPACT = sidebarCompact ? 1 : 0;

  // RECURSIVE FUNCTION TO RENDER MULTI LEVEL MENU
  const renderLevels = data => {
    return data.map((item, index) => {
      // MENU LABEL DESIGN
      if (item.type === 'label') {
        return <ListLabel key={index} compact={COMPACT}>
            {t(item.label)}
          </ListLabel>;
      }

      // MENU LIST WITH CHILDREN
      if (item.children) {
        return <SidebarAccordion key={index} item={item} sidebarCompact={COMPACT}>
            {renderLevels(item.children)}
          </SidebarAccordion>;
      }

      // MENU ITEM WITH EXTERNAL LINK
      if (item.type === 'extLink') {
        return <ExternalLink key={index} href={item.path} rel="noopener noreferrer" target="_blank">
            <NavItemButton key={item.name} name="child" active={0}>
              {item.icon ? <item.icon sx={ICON_STYLE(0)} /> : <span className="item-icon icon-text">{item.iconText}</span>}

              <ItemText compact={COMPACT} active={activeRoute(item.path)}>
                {item.name}
              </ItemText>
            </NavItemButton>
          </ExternalLink>;
      }
      return <NavItemButton key={index} disabled={item.disabled} active={activeRoute(item.path)} onClick={() => handleNavigation(item.path)}>
          {item?.icon ? <item.icon sx={ICON_STYLE(activeRoute(item.path))} /> : <BulletIcon active={activeRoute(item.path)} />}
{/* 
          <ItemText compact={COMPACT} active={activeRoute(item.path)}>
            {
              findMethodInString(item.name , Object.keys(API_METHODS))? 

              <>
                <Chip label="POST" color="primary" size="small" />  t(item.name)
              </>
              : 
              t(item.name)
            
            }
          </ItemText> */}

        <ItemText compact={COMPACT} active={activeRoute(item.path)}>
          {
            displayItem(item.name)
          }
        </ItemText>

        </NavItemButton>;
    });
  };

  // USER ROLE BASED ON FILTER NAVIGATION
  const filterNavigation = useMemo(() => {
    return navigations.filter(navigation => {
      if (!navigation.access) return true;else if (navigation.access === user?.role) return true;else return false;
    });
  }, [user?.role]);
  return <>{renderLevels(filterNavigation)}</>;
}